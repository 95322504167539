export const ACQUISITION = 'acquisition';
export const AUTOSUGGEST_CONTROLS_ACTIVATED = 'Autosuggest_Controls_Activated';
export const AUTOSUGGEST_RESULT_SELECTED = 'Autosuggest_Result_Selected';
export const AUTOSUGGEST_RESULTS_UPDATED = 'Autosuggest_Results_Updated';
export const UNSET_TELEMETRY_EVENT_TYPE = 'Unset_telemetry_event_type';
export const BACK_BUTTON_TAPPED = 'Back_Button_Tapped';
export const BOOKING_CONFIDENCE_MESSAGE_DISPLAYED = 'Booking_Confidence_Message_Displayed';
export const BOOKING_CONFIDENCE_MESSAGE_DISMISSED_X = 'Booking_Confidence_Message_Dismissed_X';
export const BOOKING_CONFIDENCE_MESSAGE_DISMISSED_CONTINUE =
  'Booking_Confidence_Message_Dismissed_Continue';
export const BOOKING_CONFIDENCE_MESSAGE_DISMISSED_BACKGROUND =
  'Booking_Confidence_Message_Dismissed_Background';
export const BOOKING_CONFIDENCE_MESSAGE_CLICKED = 'Booking_Confidence_Message_Clicked';
export const MULTI_BOOKING_CONFIDENCE_MESSAGE_DISPLAYED =
  'Multi_Booking_Confidence_Message_Displayed';
export const MULTI_BOOKING_CONFIDENCE_MESSAGE_DISSMISSED_X =
  'Multi_Booking_Confidence_Message_Dismissed_X';
export const MULTI_BOOKING_CONFIDENCE_MESSAGE_DISSMISSED_CONTINUE =
  'Multi_Booking_Confidence_Message_Dismissed_Continue';
export const MULTI_BOOKING_CONFIDENCE_MESSAGE_DISSMISSED_BACKGROUND =
  'Multi_Booking_Confidence_Message_Dismissed_Background';
export const MULTI_BOOKING_CONFIDENCE_MESSAGE_DISSMISSED_CLOSED =
  'Multi_Booking_Confidence_Message_Dismissed_Closed';

export const MULTIMODAL_ITINERARY_VIEWED = 'Multimodal_Itinerary_Viewed';
export const PARTNER_MESSAGES_RENDERED = 'Partner_Messages_Rendered';
export const PARTNER_MESSAGE_CLICKED = 'Partner_Message_Clicked';
export const DEAL_MESSAGES_RENDERED = 'Deal_Messages_Rendered';
export const DEAL_MESSAGE_CLICKED = 'Deal_Message_Clicked';
export const PARTNER_TRUST_BADGE_CLICKED = 'Partner_Trust_Badge_Clicked';
export const PARTNER_TRUST_INFORMATION_CLOSED = 'Partner_Trust_Information_Closed';
export const PARTNER_TRUST_BADGES_RENDERED = 'Partners_With_Trust_Badge_Rendered';
export const PARTNER_WITH_TRUST_BADGE_SELECTED = 'Partner_With_Trust_Badge_Selected';
export const CALENDAR_CTA_TAPPED = 'Calendar_CTA_Tapped';
export const CALENDAR_TAB_SWITCHED = 'Calendar_Tab_Switched';
export const CAR_HIRE_CARD_LOADED = 'Car_Hire_Card_Loaded';
export const CAR_HIRE_CARD_TAPPED = 'Car_Hire_Card_Tapped';
export const CAR_HIRE_CROSS_SELL_FALLBACK_LOADED = 'Car_Hire_Cross_Sell_Fallback_Loaded';
export const CAR_HIRE_CROSS_SELL_TAPPED = 'Car_Hire_Cross_Sell_Tapped';
export const CAR_HIRE_PILL_TAPPED = 'Car_Hire_Pill_Tapped';
export const CAR_HIRE_SHOW_MORE_BUTTON_TAPPED = 'Car_Hire_Show_More_Button_Tapped';
export const CHECKING_PRICES_SPINNER_ACTIVE_DURATION = 'Checking_Prices_Spinner_Active_Duration';
export const COMBINED_EXPLORE = 'Combined_Explore';
export const COMBINED_EXPLORE_RESULT_LOADED = 'Combined_Explore_Result_Loaded';
export const COMBINED_RESULTS_ITINERARY_HEART_ICON_CLICKED =
  'Combined_Results_Itinerary_Heart_Icon_Clicked';
export const COMBINED_RESULTS_FLOATING_SUCCESS_NOTIFICATION_CTA_CLICKED =
  'Combined_Results_Floating_Success_Notification_CTA_Clicked';
export const CRIMEA_BANNER_CLOSED = 'Crimea_Banner_Closed';
export const CUBA_BANNER_CLOSED = 'Cuba_Banner_Closed';
export const CULTURE_SELECTOR_TAPPED = 'Culture_Selector_Tapped';
export const DATE_INPUT_CLEARED = 'Date_Input_Cleared';
export const DATE_INPUT_TAPPED = 'Date_Input_Tapped';
export const DATE_PICKER_DEFAULT_TAB = 'Date_Picker_Default_Tab';
export const DATE_SELECTED = 'Date_Selected';
export const DAY_VIEW = 'Day_View';
export const DESTINATION_SELECTED = 'Destination_Selected';
export const DESTINATION_SELECTOR_LOADED = 'Destination_Selector_Loaded';
export const DESTINATION_SELECTOR_MULTI_CITY_LINK_TAPPED =
  'Destination_Selector_Multi_City_Link_Tapped';
export const DIRECT_FLIGHTS_TOGGLED = 'Direct_Flights_Toggled';
export const EXPERIMENT_CHOKE_POINT = 'Experiment_Choke_Point';
export const EXPERIMENT_SMART_METRIC = 'Experiment_Smart_Metric';
export const FLIGHT_BOOKING_PANEL_ITINERARY_AVAILABILITY_ESTABLISHED =
  'Flight_Booking_Panel_Itinerary_Availability_Established';
export const FLIGHT_BOOKING_PANEL_ITINERARY_AVAILABILITY_REVOKED =
  'Flight_Booking_Panel_Itinerary_Availability_Revoked';
export const FLIGHT_BOOKING_PANEL_CLOSED = 'Flight_Booking_Panel_Closed';
export const FLIGHTS_BOOKING_PANEL_FETCH = 'Flights_Booking_Panel_Fetch';
export const FLIGHT_BOOKING_PANEL_FIRST_RENDERED = 'Flight_Booking_Panel_First_Rendered';
export const FLIGHT_BOOKING_PANEL_FLOATING_SUCCESS_NOTIFICATION_CTA_CLICKED =
  'Flight_Booking_Panel_Floating_Success_Notification_CTA_Clicked';
export const FLIGHT_BOOKING_PANEL_ITINERARY_HEART_ICON_CLICKED =
  'Flight_Booking_Panel_Itinerary_Heart_Icon_Clicked';
export const FLIGHT_BOOKING_PANEL_UNAVAILABLE_ITINERARY_RENDERED =
  'Flight_Booking_Panel_Unavailable_Itinerary_Rendered';
export const FLIGHT_BOOKING_PANEL_FIRST_PRICING_OPTION_PRICE_CHANGED =
  'Flight_Booking_Panel_First_Pricing_Option_Price_Changed';
export const FLIGHT_DATE_SELECTION_SCREEN_SHOWN = 'Flight_Date_Selection_Screen_Shown';
export const FLIGHT_DAY_VIEW_FILTER_OPENED = 'Flight_Day_View_Filter_Opened';
export const FLIGHT_DAY_VIEW_PARTNER_SELECTED = 'Flight_Day_View_Partner_Selected';
export const FLIGHT_DAY_VIEW_SORT_OPENED = 'Flight_Day_View_Sort_Opened';
export const FLIGHT_FILTERING_PRESETS_TAPPED = 'Flight_Filtering_Presets_Tapped';
export const FLIGHT_ITINERARY_PRICING_OPTION = 'Flight_Itinerary_Pricing_Option';
export const FLIGHT_PILL_TAPPED = 'Flight_Pill_Tapped';
export const FLIGHTS_FILTER_INTERACTION_TRIGGERED = 'Flights_Filter_Interaction_Triggered';
export const FULL_ITINERARY_SHOWN = 'Full_Itinerary_Shown';
export const GLOBAL_NAV_FALLBACK_CARD_CLICKED = 'Global_Nav_Fallback_Card_Clicked';
export const GLOBAL_NAV_FALLBACK_CARD_LOADED = 'Global_Nav_Fallback_Card_Loaded';
export const GLOBAL_NAV_CARD_CLICKED = 'Global_Nav_Card_Clicked';
export const GLOBAL_NAV_CARD_LOADED = 'Global_Nav_Card_Loaded';
export const GLOBAL_NAV_RESPONSE_FAILURE = 'Global_Nav_Response_Failure';
export const GLOBAL_HEADER_LOGO_CLICKED = 'Global_Header_Logo_Clicked';
export const GLOBAL_HEADER_CULTURE_SELECTOR_CLICKED = 'Global_Header_Culture_Selector_Clicked';
export const GLOBAL_HEADER_LOGIN_CLICKED = 'Global_Header_LogIn_Clicked';
export const GLOBAL_HEADER_HEART_ICON_CLICKED = 'Global_Header_Heart_Icon_Clicked';
export const GLOBAL_HEADER_PROFILE_CLICKED = 'Global_Header_Profile_Clicked';
export const GLOBAL_HEADER_MENU_OPENED = 'Global_Header_Menu_Opened';
export const GLOBAL_HEADER_MENU_CLOSED = 'Global_Header_Menu_Closed';
export const GLOBAL_HEADER_MENU_ITEM_CLICKED = 'Global_Header_Menu_Item_Clicked';
export const HOMEPAGE_AD_LOADED = 'Homepage_Ad_Loaded';
export const HOMEPAGE_AD_TAPPED = 'Homepage_Ad_Tapped';
export const HOMEPAGE_AD_VIDEO_COMPLETED = 'Homepage_Ad_Video_Completed';
export const HOMEPAGE_AD_VIDEO_STOPPED = 'Homepage_Ad_Video_Stopped';
export const HOMEPAGE_FALLBACK_AD_LOADED = 'Homepage_Fallback_Ad_Loaded';
export const HOMEPAGE_FALLBACK_AD_TAPPED = 'Homepage_Fallback_Ad_Tapped';
export const HOMEPAGE = 'Homepage';
export const HOMEPAGE_TABS_LOADED = 'Homepage_Tabs_Loaded';
export const HOMEPAGE_TABS_TAPPED = 'Homepage_Tabs_Tapped';
export const HOTEL_CARD_TAPPED = 'Hotel_Card_Tapped';
export const HOTEL_PILL_TAPPED = 'Hotel_Pill_Tapped';
export const HOTELS_CROSS_SELL_FALLBACK_LOADED = 'Hotels_Cross_Sell_Fallback_Loaded';
export const HOTELS_CROSS_SELL_TAPPED = 'Hotels_Cross_Sell_Tapped';
export const ITINERARY_LEG_CLICKED = 'Itinerary_Leg_Clicked';
export const ITINERARY_DETAILS_DISPLAYED = 'Itinerary_Details_Displayed';
export const ITINERARY_DETAILS_CLOSE_CLICKED = 'Itinerary_Details_Close_Clicked';
export const ITINERARY_SEGMENT_DURATION_HIDDEN = 'Itinerary_Segment_Duration_Hidden';
export const LOGIN_OPENED = 'Login Opened';
export const LOGIN_BUTTON_CLICKED = 'Login Button Clicked';
export const MARKETING_OPT_IN_DISPLAYED = 'Marketing Consent Screen Displayed';
export const MARKETING_OPT_IN_CLICKED = 'Marketing Consent Opt in Clicked';
export const MARKETING_OPT_IN_SUCCEEDED = 'Marketing Opt in Succeeded';
export const MARKETING_OPT_IN_FAILED = 'Marketing Opt in Failed';
export const MASHUP_SCREEN_DISPLAYED = 'Mashup_Screen_Displayed';
export const MASHUP_FARE_AVAILABLE = 'Mashup_Fare_Available';
export const MONTH_SELECTED = 'Month_Selected';
export const MONTH_VIEW_LINK_TAPPED = 'Month_View_Link_Tapped';
export const MONTH_VIEW_TAB_LOADED = 'Month_View_Tab_Loaded';
export const MOROCCO_BANNER_CLOSED = 'Morocco_Banner_Closed';
export const NO_FLIGHTS_LOADED = 'No_Flights_Loaded';
export const ORIGIN_SELECTED = 'Origin_Selected';
export const ORIGIN_SELECTOR_LOADED = 'Origin_Selector_Loaded';
export const ONE_WAY_RETURN_DROPDOWN_LOADED = 'One_Way_Return_Dropdown_Loaded';
export const ONE_WAY_RETURN_DROPDOWN_TRIP_CHANGED = 'One_Way_Return_Dropdown_Trip_Changed';
export const ONE_WAY_RETURN_DROPDOWN_CLICKED = 'One_Way_Return_Dropdown_Clicked';
export const PASSENGER_INFO_CLICKED = 'Passenger_Info_Clicked';
export const PQS_CLICKED = 'PQS_Clicked';
export const PQS_SURVEY_SEEN = 'PQS_Survey_Seen';
export const PRICING_ITEM_CLICKED = 'Pricing_Item_Clicked';
export const PRICING_OPTION_FILTER_ENABLED = 'Pricing_Option_Filter_Enabled';
export const PRICING_OPTION_FILTER_DISABLED = 'Pricing_Option_Filter_Disabled';
export const PRICING_OPTION_SPINNERS_ACTIVE_DURATION = 'Pricing_Option_Spinners_Active_Duration';
export const PROVIDER_SELECTION_PQS_FIND_OUT_MORE_CLICKED =
  'Provider_Selection_PQS_Find_Out_More_Clicked';
export const READ_BEFORE_BOOKING_CLICKED = 'Read_Before_Booking_Clicked';
export const READ_BEFORE_BOOKING_DISPLAYED = 'Read_Before_Booking_Displayed';
export const READ_BEFORE_BOOKING_CLOSE_CLICKED = 'Read_Before_Booking_Close_Clicked';
export const SEARCH_BOX_INTERACTION = 'Search_Box_Interaction';
export const SEARCH_BUTTON_TAPPED = 'Search_Button_Tapped';
export const SEARCH_SUMMARY_TAPPED = 'Search_Summary_Tapped';
export const SEARCH_CONTROL_SUMMARY_CLICKED = 'Search_Control_Summary_Clicked';
export const SEARCH_CONTROL_HIDDEN = 'Search_Control_Hidden';
export const SELF_TRANSFER_BANNER_RENDERED = 'Self_Transfer_Banner_Rendered';
export const SELF_TRANSFER_BANNER_CLICKED = 'Self_Transfer_Banner_Clicked';
export const SELF_TRANSFER_BANNER_DISPLAYED = 'Self_Transfer_Banner_Displayed';
export const SELF_TRANSFER_BANNER_CLOSED = 'Self_Transfer_Banner_Closed';
export const SHOW_MORE_FLIGHTS_TAPPED = 'Show_More_Flights_Tapped';
export const SHOW_MORE_HOTELS_TAPPED = 'Show_More_Hotels_Tapped';
export const SINGLE_UNPRICED_ITEM_RENDERED = 'Single_Unpriced_Item_Rendered';
export const SOCIAL_LOGIN_FAILED = 'Social Login Failed';
export const SONAR_MIGRATION = 'Sonar_Migration';
export const TRAVELLER_SELECTOR_LOADED = 'Traveller_Selector_Loaded';
export const VIEW_AIRLINE_FEES_CLICKED = 'View_Airline_Fees_Clicked';
export const CE_PILL_SELECTED = 'CE_Pill_Selected';
export const CE_RESULT_SELECTED = 'CE_Result_Selected';
export const CE_CHANGE_SEARCH_PARAMS = 'CE_Change_Search_Params';
export const CE_EXPLORE_EVERYWHERE = 'CE_Explore_Everywhere';
export const CR_FINISH_LOADING = 'CR_Finish_Loading';
export const PQS_RATING = 'Pqs_Rating';
export const PQS_EXPERIENCE = 'Pqs_Experience';
export const MASHUP_OPTION_SELECTED = 'Mashup_Option_Selected';
export const MASHUP_PANEL_CLOSED = 'Mashup_Panel_Closed';
export const ACORN_BOOKING_PANEL_URL_DETECTED = 'Acorn_Booking_Panel_Url_Detected';
export const PSS_OPENED_FROM_MASHUP_OPTION = 'Pss_Opened_From_Mashup_Option';
export const SIMPLE_ITINERARY_CLICKED = 'Simple_Itinerary_Clicked';
export const SIMPLE_ITINERARY_BACK_TO_TOP_CLICKED = 'Simple_Itinerary_Back_To_Top_Clicked';
export const ORIGIN_DESTINATION_SWITCH_BUTTON_CLICKED = 'Origin_Destination_Switch_Button_Clicked';
export const SAVED_LIMIT_ERROR_DIALOG_DISMISSED = 'Saved_Limit_Error_Dialog_Dismissed';
export const USERS_INTERACTION_WITH_NEARBY_AIRPORTS = 'Users_Interaction_With_Nearby_Airports';
export const USERS_SWITCH_ON_NEARBY_AIRPORTS = 'Users_Switch_On_Nearby_Airports';
export const WEB_PERFORMANCE = 'Web_Performance';
export const TRACKING_PREFERENCES_CHANGED = 'Tracking_Preferences_Changed';

// Rail Events
export const RAIL_ITINERARY_CLICKED = 'Rail_Itinerary_Clicked';
export const RAIL_SEE_MORE_CLICKED = 'Rail_See_More_Clicked';
export const RAIL_DRAWER_OPENED = 'Rail_Drawer_Opened';
export const RAIL_DRAWER_CLOSED = 'Rail_Drawer_Closed';
export const RAIL_BANNER_LOADED = 'Rail_Banner_Loaded';
export const RAIL_BANNER_CLICKED = 'Rail_Banner_Clicked';

export const GLOBAL_COMPONENT_GRAPPLER_EVENT = 'Global_Component_Grappler_Event';

export const SPONSORED_PRICING_OPTION_CLICK = 'Sponsored_Pricing_Option_Click';
export const SPONSORED_PRICING_OPTION_IMPRESSION = 'Sponsored_Pricing_Option_Impression';
export const SPONSORED_PRICING_OPTION_DELIVERY = 'Sponsored_Pricing_Option_Delivery';
export const SPONSORED_PRICING_OPTION_NO_MATCH = 'Sponsored_Pricing_Option_No_Match';

// Destination Preview Events
export const DESTINATION_PREVIEW_SECTION_MOUNTED = 'Destination_Preview_Section_Mounted';
export const DESTINATION_PREVIEW_SECTION_VIEWED = 'Destination_Preview_Section_Viewed';
export const DESTINATION_PREVIEW_MOUNTED = 'Destination_Preview_Mounted';
export const DESTINATION_PREVIEW_UNMOUNTED = 'Destination_Preview_Unmounted';
export const DESTINATION_PREVIEW_CAROUSEL_IMAGE_CLICKED =
  'Destination_Preview_Carousel_Image_Clicked';
export const DESTINATION_PREVIEW_CAROUSEL_PAGE_SELECTED =
  'Destination_Preview_Carousel_Page_Selected';
export const DESTINATION_PREVIEW_DESTINATION_VIBES_CLICKED =
  'Destination_Preview_Destination_Vibes_Clicked';
export const DESTINATION_PREVIEW_TRIP_GRID_CLICKED = 'Destination_Preview_Trip_Grid_Clicked';
export const DESTINATION_PREVIEW_TRIP_GRID_CTA_CLICKED =
  'Destination_Preview_Trip_Grid_CTA_Clicked';
export const DESTINATION_PREVIEW_INSPIRATIONAL_TABS_CLICKED =
  'Destination_Preview_Inspirational_Tabs_Clicked';
export const DESTINATION_PREVIEW_STORIES_PLAY_BUTTON_CLICKED =
  'Destination_Preview_Stories_Play_Button_Clicked';
export const DESTINATION_PREVIEW_STORIES_PAUSE_BUTTON_CLICKED =
  'Destination_Preview_Stories_Pause_Button_Clicked';
export const DESTINATION_PREVIEW_STORIES_VIDEO_CLICKED =
  'Destination_Preview_Stories_Video_Clicked';
export const DESTINATION_PREVIEW_STORIES_MUTE_BUTTON_CLICKED =
  'Destination_Preview_Stories_Mute_Button_Clicked';
export const DESTINATION_PREVIEW_STORIES_UNMUTE_BUTTON_CLICKED =
  'Destination_Preview_Stories_Unmute_Button_Clicked';
export const DESTINATION_PREVIEW_STORIES_PAGE_SELECTED =
  'Destination_Preview_Stories_Page_Selected';
export const DESTINATION_PREVIEW_SIGHTSEEING_CARD_CLICKED =
  'Destination_Preview_Sightseeing_Card_Clicked';
export const DESTINATION_PREVIEW_FLIGHT_PRICE_CHART_CLICKED =
  'Destination_Preview_Flight_Price_Chart_Clicked';
export const DESTINATION_PREVIEW_FLIGHT_PRICE_CHART_CTA_CLICKED =
  'Destination_Preview_Flight_Price_Chart_CTA_Clicked';
export const DESTINATION_PREVIEW_ITINERARY_CARD_CLICKED =
  'Destination_Preview_Itinerary_Card_Clicked';
export const DESTINATION_PREVIEW_ITINERARY_LIST_CTA_CLICKED =
  'Destination_Preview_Itinerary_List_CTA_Clicked';
export const DESTINATION_PREVIEW_HOTEL_CARD_CLICKED = 'Destination_Preview_Hotel_Card_Clicked';
export const DESTINATION_PREVIEW_HOTEL_LIST_CTA_CLICKED =
  'Destination_Preview_Hotel_List_CTA_Clicked';
export const DESTINATION_PREVIEW_NEIGHBOURHOODS_TABS_CLICKED =
  'Destination_Preview_Neighbourhoods_Tabs_Clicked';
export const DESTINATION_PREVIEW_NEIGHBOURHOODS_CARD_CLICKED =
  'Destination_Preview_Neighbourhoods_Card_Clicked';
export const DESTINATION_PREVIEW_NEIGHBOURHOODS_VIBES_CLICKED =
  'Destination_Preview_Neighbourhoods_Vibes_Clicked';
export const DESTINATION_PREVIEW_NEIGHBOURHOODS_LIST_CTA_CLICKED =
  'Destination_Preview_Neighbourhoods_List_CTA_Clicked';

// Packages POC events
export const PACKAGES_BANNER_CLICKED = 'Packages_Banner_Clicked';
export const PACKAGES_BANNER_RENDERED = 'Packages_Banner_Rendered';
